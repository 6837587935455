var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('material-card',{staticClass:"px-5 py-3",attrs:{"color":"pink"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('base-title',[_vm._v(" Current Cut Weights ")]),_c('v-tabs',{attrs:{"show-arrows":"","background-color":"transparent","slider-color":"white"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_vm._v(" Lots ")]),_vm._l((_vm.tabData),function(tab,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s(tab.tabName)+" ")])})],2)]},proxy:true}])},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.tableHeaders,"items":_vm.lots},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.id && _vm.$store.getters.can('view-lots'))?_c('router-link-id',{attrs:{"id":item.id,"name":"lot"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.inventory_summary.name",fn:function(ref){
var item = ref.item;
return [(item.inventory_summary && item.inventory_summary.name)?_c('router-link-id',{attrs:{"id":item.inventory_summary.id,"name":"inventory"}},[_vm._v(" "+_vm._s(item.inventory_summary.name)+" ")]):_vm._e()]}},{key:"item.designation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(_vm.getTextGivenValue(item.designation, _vm.lotTypes)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(_vm.getTextGivenValue(item.status, _vm.lotStatuses)))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.grandTotals.containers))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.gross))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.tare))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.net))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.pieces))])])])],2)],1),_vm._l((_vm.tabData),function(tab,index){return _c('v-tab-item',{key:index},[_c('smelter-cut-weights',{attrs:{"lots":tab.lots}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }