var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.tableHeaders,"items":_vm.lots},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.id,"name":"lot"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.inventory_summary.name",fn:function(ref){
var item = ref.item;
return [(item.inventory_summary && item.inventory_summary.name)?_c('router-link-id',{attrs:{"id":item.inventory_summary.id,"name":"inventory"}},[_vm._v(" "+_vm._s(item.inventory_summary.name)+" ")]):_vm._e()]}},{key:"item.designation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(_vm.getTextGivenValue(item.designation, _vm.lotTypes)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(_vm.getTextGivenValue(item.status, _vm.lotStatuses)))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.grandTotals.containers))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.gross))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.tare))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.net))]),_c('th',[_vm._v(_vm._s(_vm.grandTotals.pieces))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }