<template>
  <v-data-table
    multi-sort
    :headers="tableHeaders"
    :items="lots"
  >
    <template v-slot:item.name="{ item }">
      <router-link-id
        :id="item.id"
        name="lot"
      >
        <v-btn small>
          {{ item.name }}
        </v-btn>
      </router-link-id>
    </template>
    <template v-slot:item.inventory_summary.name="{ item }">
      <router-link-id
        v-if="item.inventory_summary && item.inventory_summary.name"
        :id="item.inventory_summary.id"
        name="inventory"
      >
        {{ item.inventory_summary.name }}
      </router-link-id>
    </template>
    <template v-slot:item.designation="{ item }">
      {{ getTextGivenValue(item.designation, lotTypes) | filterNA }}
    </template>
    <template v-slot:item.status="{ item }">
      {{ getTextGivenValue(item.status, lotStatuses) | filterNA }}
    </template>
    <template slot="body.append">
      <tr>
        <th />
        <th />
        <th />
        <th />
        <th>{{ grandTotals.containers }}</th>
        <th>{{ grandTotals.gross }}</th>
        <th>{{ grandTotals.tare }}</th>
        <th>{{ grandTotals.net }}</th>
        <th>{{ grandTotals.pieces }}</th>
      </tr>
    </template>
  </v-data-table>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'SmelterCutWeights',
  props: {
    lots: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'designation' },
      { text: 'Status', value: 'status' },
      { text: 'Inventory', value: 'inventory_summary.name' },
      { text: 'Containers', value: 'inventory_summary.containers' },
      { text: 'Gross', value: 'inventory_summary.gross' },
      { text: 'Tare', value: 'inventory_summary.tare' },
      { text: 'Net', value: 'inventory_summary.net' },
      { text: 'Units', value: 'inventory_summary.pieces' }
    ]
  }),
  computed: {
    ...mapGetters('lotStore', [
      'lotStatuses',
      'lotTypes'
    ]),
    grandTotals () {
      return this.lots.reduce((accum, row) => {
        if (!row.inventory_summary) return accum
        accum.containers += Number(row.inventory_summary.containers) || 0
        accum.gross += Number(row.inventory_summary.gross) || 0
        accum.tare += Number(row.inventory_summary.tare) || 0
        accum.net += Number(row.inventory_summary.net) || 0
        accum.pieces += Number(row.inventory_summary.pieces) || 0
        return accum
      }, { containers: 0, gross: 0, tare: 0, net: 0, pieces: 0 })
    }
  }
}
</script>
