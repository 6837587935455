<template>
  <div>
    <material-card
      color="pink"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <base-title>
          Current Cut Weights
        </base-title>
        <v-tabs
          v-model="tabs"
          show-arrows
          background-color="transparent"
          slider-color="white"
        >
          <v-tab>
            Lots
          </v-tab>
          <v-tab
            v-for="(tab, index) in tabData"
            :key="index"
          >
            {{ tab.tabName }}
          </v-tab>
        </v-tabs>
      </template>
      <v-skeleton-loader
        v-if="loading"
        type="table"
      />
      <div v-else>
        <v-tabs-items
          v-model="tabs"
          class="transparent"
        >
          <v-tab-item>
            <v-data-table
              multi-sort
              :headers="tableHeaders"
              :items="lots"
            >
              <template v-slot:item.name="{ item }">
                <router-link-id
                  v-if="item.id && $store.getters.can('view-lots')"
                  :id="item.id"
                  name="lot"
                >
                  <v-btn small>
                    {{ item.name }}
                  </v-btn>
                </router-link-id>
                <span v-else>
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item.inventory_summary.name="{ item }">
                <router-link-id
                  v-if="item.inventory_summary && item.inventory_summary.name"
                  :id="item.inventory_summary.id"
                  name="inventory"
                >
                  {{ item.inventory_summary.name }}
                </router-link-id>
              </template>
              <template v-slot:item.designation="{ item }">
                {{ getTextGivenValue(item.designation, lotTypes) | filterNA }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ getTextGivenValue(item.status, lotStatuses) | filterNA }}
              </template>
              <template slot="body.append">
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th>{{ grandTotals.containers }}</th>
                  <th>{{ grandTotals.gross }}</th>
                  <th>{{ grandTotals.tare }}</th>
                  <th>{{ grandTotals.net }}</th>
                  <th>{{ grandTotals.pieces }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item
            v-for="(tab, index) in tabData"
            :key="index"
          >
            <smelter-cut-weights
              :lots="tab.lots"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </material-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import SmelterCutWeights from './SmelterCutWeights'

export default {
  name: 'CurrentCutWeights',
  components: {
    SmelterCutWeights
  },
  data: () => ({
    loading: false,
    tabs: 0,
    tableHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'designation' },
      { text: 'Status', value: 'status' },
      { text: 'Inventory', value: 'inventory_summary.name' },
      { text: 'Containers', value: 'inventory_summary.containers' },
      { text: 'Gross', value: 'inventory_summary.gross' },
      { text: 'Tare', value: 'inventory_summary.tare' },
      { text: 'Net', value: 'inventory_summary.net' },
      { text: 'Units', value: 'inventory_summary.pieces' }
    ],

    lots: [],
    smelters: []
  }),
  computed: {
    ...mapGetters('lotStore', [
      'lotStatuses',
      'lotTypes'
    ]),
    tabData () {
      const tabNames = this.tabNames
      let tabList = []
      tabNames.forEach(tabName => {
        const lots = this.getLots(tabName.toLowerCase())
        if (lots.length) {
          tabList.push({ tabName: tabName, lots })
        }
      })
      return tabList
    },
    tabNames () {
      const exclude = ['multimetco']
      let names = [
        'Stillwater',
        'BASF',
        'DR2',
        'Hensel',
        'PGM Processing'
      ]
      this.smelters.forEach(sm => {
        const name = sm.name
        if (exclude.includes(name.toLowerCase())) return
        if (names.includes(name)) return
        names.push(name)
      })
      return names
    },
    grandTotals () {
      return this.lots.reduce((accum, row) => {
        if (!row.inventory_summary) return accum
        accum.containers += Number(row.inventory_summary.containers) || 0
        accum.gross += Number(row.inventory_summary.gross) || 0
        accum.tare += Number(row.inventory_summary.tare) || 0
        accum.net += Number(row.inventory_summary.net) || 0
        accum.pieces += Number(row.inventory_summary.pieces) || 0
        return accum
      }, { containers: 0, gross: 0, tare: 0, net: 0, pieces: 0 })
    }
  },
  methods: {
    getLots (name) {
      const smelter = this.smelters.find(smelter => smelter.name.toLowerCase() === name)
      if (!smelter) return []
      return this.lots.filter(row => row.smelter === smelter.url)
    },
    fetchSmelters () {
      return axios.get('/api/smelters/?page_size=0&fields=url,name').then(r => {
        this.smelters = r.data
      })
    },
    fetchLots () {
      const fields = ['id', 'name', 'status', 'inventory_summary', 'designation', 'smelter'].join(',')
      const params = { page_size: 0, status__in: ['HE', 'CU', 'CT'].join(','), fields }
      return axios.get('/api/lots/', { params }).then(r => {
        this.lots = r.data
      })
    },
    async init () {
      this.loading = true
      await Promise.all([
        this.fetchLots(),
        this.fetchSmelters()
      ])
      this.loading = false
    }
  },
  mounted () {
    this.init()
  }
}
</script>
